@font-face {
    font-family: 'Avenir';
    src: url('AvenirLTStd-Heavy.woff2') format('woff2'),
        url('AvenirLTStd-Heavy.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('AvenirLTStd-Roman.woff2') format('woff2'),
        url('AvenirLTStd-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

