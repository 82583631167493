#root {
  width: 100%;
  height: 100%;
}

.OTPublisherContainer {
  max-width: 100%;
}

.viewer-audio-publisher .alert {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.viewer-audio-publisher .OT_video-poster {
  display: none !important;
}

.viewer-list-item .buttons {
  display: none;
  margin: -0.75rem;
}

.viewer-list-item:hover .buttons {
  display: flex;
}

.chat .message {
  display: flex;
  padding: 0.25rem 0.5rem;
  align-items: center;
  justify-content: space-between;
}

.chat .message .btn {
  display: none;
}

.chat .message:hover .btn {
  display: inline-flex;
}

.admin-controls .tab-content {
  flex: 1
}

.admin-controls-tabs a {
  flex: 1;
  text-align: center;
  color: #FFFFFF;
  outline: none;
}

.admin-controls-tabs a.nav-link:hover {
  border-color: transparent ;
}

img {
  max-width: 100%;
}